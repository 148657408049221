import React from "react";
import { NavLink, useLocation } from 'react-router-dom';

const Menu = () => {

    const location = useLocation();

    const isActiveLink = (path) => {
        return location.pathname === path;
    };
  
    return (
        <>
            <div className="themenu">

                <div className={`themenulinks ${isActiveLink('/')  ? 'active' : ''}`}>
                <NavLink exact to="/" activeClassName="active">/home</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/glass') ? 'active' : ''}`}>
                <NavLink to="/glass" activeClassName="active">/glass</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/nfts') ? 'active' : ''}`}>
                <NavLink to="/nfts" activeClassName="active">/nfts</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/webdev') ? 'active' : ''}`}>
                <NavLink to="/webdev" activeClassName="active">/webdev</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/marketing') ? 'active' : ''}`}>
                <NavLink to="/marketing" activeClassName="active">/marketing</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/addlexp') ? 'active' : ''}`}>
                <NavLink to="/addlexp" activeClassName="active">/additional experience</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/books') ? 'active' : ''}`}>
                <NavLink to="/books" activeClassName="active">/books & writing</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/skills') ? 'active' : ''}`}>
                <NavLink to="/skills" activeClassName="active">/skills & resumes</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/carpentry') ? 'active' : ''}`}>
                <NavLink to="/carpentry" activeClassName="active">/carpentry</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/education') ? 'active' : ''}`}>
                <NavLink to="/education" activeClassName="active">/education</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/music') ? 'active' : ''}`}>
                <NavLink to="/music" activeClassName="active">/music</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/art') ? 'active' : ''}`}>
                <NavLink to="/art" activeClassName="active">/art</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/other') ? 'active' : ''}`}>
                <NavLink to="/other" activeClassName="active">/other</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/socials') ? 'active' : ''}`}>
                <NavLink to="/socials" activeClassName="active">/socials</NavLink>
                </div>

                <div className={`themenulinks ${isActiveLink('/contact') ? 'active' : ''}`}>
                <NavLink to="/contact" activeClassName="active">/contact</NavLink>
                </div>

            <div className="clear"></div>

            </div>
        </>

    );
};

export default Menu;