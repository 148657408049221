import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";


const copyToClipboard = () => {
  const resumeText = document.getElementById('resumeText').innerText;
  let totalText = '';
  const additionalText = document.getElementById('additionalText').value;
  if (additionalText) {
    let middleMessage = '\n____________________________________________________________________\nJIM DEE IS A PERFECT FIT FOR THE FOLLOWING ROLE: \n\n';
    totalText = resumeText + middleMessage + additionalText; 
  } else {
    totalText = resumeText + '\n' + additionalText; 
  }
  navigator.clipboard.writeText(totalText).then(() => {
    alert('Resume text copied to clipboard!');
  }).catch((err) => {
    console.error('Failed to copy text: ', err);
  });
}


const text = `
JIM DEE  
Jim@GenerativeNFTs.io   •   (503) 891-7153
jpd3.com   •   linkedin.com/in/jimdee   •   medium.com/@jimdee

____________________________________________________________________

PROFESSIONAL SUMMARY

Dynamic marketing and social media expert with extensive experience in content creation, digital marketing, and brand strategy. Proven ability to drive engagement, enhance brand presence, and manage high-impact campaigns across multiple social media platforms. Adept at leveraging technical and creative skills to build strong online communities and foster business growth. Since 2021, my marketing strategies have resulted, directly and indirectly, in more than $40 million in sales. Well-accustomed to remote work / consultative scenarios.

____________________________________________________________________

PROFESSIONAL EXPERIENCE

★ Co-Founder / Chief Generative NFT Coder / Project Lead ⟶ GenerativeNFTs.io

• Built and managed a popular web3/NFT blog, sharing content widely across social media channels.
• See: https://medium.com/web-design-web-developer-magazine
• Developed and executed social media strategies to promote generative NFT projects, driving significant engagement and sales growth.
• Created compelling content, including articles, visuals, and videos, to enhance brand presence and audience interaction.
• Engaged with a global audience, fostering a strong online community and expanding the platform's reach.


★ Principal Web Strategist ⟶ Array Web Development

• Planned and executed digital marketing campaigns, focusing on social media to increase brand awareness and drive traffic.
• Published hundreds of articles and blog posts, leveraging social media to share content and boost engagement.
• Implemented SEO strategies and social media marketing, achieving substantial ROI for clients.
• Coordinated with clients to develop customized social media plans, enhancing their online presence and community engagement.


★ Director of Marketing & Business Development ⟶ Case | Sabatini CPAs

• Launched and managed a successful tax eNewsletter, promoted through various social media platforms to expand the firm's client base.
• Created high-quality marketing materials and social media content to support business development efforts.
• Utilized social media analytics to track campaign performance and adjust strategies for optimal results.
• Fostered relationships with key stakeholders and influencers to enhance social media reach and impact.


★ Senior Manager, Marketing Communications & Internet Services ⟶ Air & Waste Management Association

• Oversaw marketing communications and web-based publishing, integrating social media to amplify message reach.
• Managed PR, advertising, and social media relations for large-scale conferences and exhibitions.
• Developed and executed social media strategies to promote events, driving attendee engagement and participation.
• Enhanced online presence through strategic content management and social media marketing.


★ Marketing Communications Project Manager ⟶ Deloitte

• Wrote and managed more than 200 proposals for audit, tax, and consulting engagements.
• Developed competitive intelligence programs, utilizing social media to gather insights and inform strategies.


★ Production Editor ⟶ Prentice Hall

• Edited and managed layout for legal journals, ensuring compliance with style guides.
• Wrote feature articles for the law firm management publication, Of Counsel.

____________________________________________________________________

RELEVANT SOCIAL MEDIA SKILLS

• Social media Strategy
• Content creation & management
• Digital marketing & SEO
• Brand development & engagement
• Public relations & media relations
• Social media analytics
• Community building
• Project management
• Creative writing
• Technical writing & documentation

____________________________________________________________________

GENERAL BUSINESS SKILLS

agency experience, board of directors experience, budgeting, competitive intelligence, consulting, content strategy, creativity, critical thinking, crm, entrepreneurism, event planning, executive interfacing, hr, interpersonal skills, leadership, management, marketing communications, marketing strategy, media relations, microsoft excel, microsoft office, microsoft word, negotiation, nonprofit organizations, organization skills, partnership management, pr / public relations, presenting, problem solving, project management, proposals, public speaking, research, resourcefulness, rfps, strategic planning, strategy, supervision, team building, team leadership, teamwork

____________________________________________________________________

INDUSTRY EXPERIENCE

blockchain, construction, crypto / cryptocurrency, editorial, financial services, legal, magazines, news, nonprofit organizations, professional services, publishing, saas, startups, technology

____________________________________________________________________

PUBLICATIONS & WRITING

• Authored two novels, a novella, a feature screenplay, and a memoir.
• Published thousands of blog articles on various platforms, widely shared on social media.
• Notable articles include topics on web development, generative art coding, and digital marketing strategies.
• See https://jpd3.com/books

____________________________________________________________________

SOCIAL MEDIA, SEO, & MARKETING ARTICLES

ChatGPT has selected the following articles as best likely representations of my writing on the topic of social media, SEO, and marketing. Thank you, ChatGPT!

• '12 Guaranteed Ways To Attract Better Web Site Traffic' - https://medium.com/web-design-web-developer-magazine/12-guaranteed-ways-to-attract-better-web-site-traffic-f846619ae191?sk=a38543f9d5c6589966fb20d28f49e77c
• 'The Basics What Is Search Engine Optimization SEO' - https://medium.com/web-design-web-developer-magazine/the-basics-what-is-search-engine-optimization-seo-13de38274d69?sk=2a59b33c6acd886ddf94f5bc7ee1c3d0
• 'A Major SEO Consideration Before Starting a Medium.com Publication' - https://medium.com/web-design-web-developer-magazine/a-major-seo-consideration-before-starting-a-medium-com-publication-9cdfa2cdba25?sk=66f0e296f3f104e07c4c6ca4c3a71815
• 'Sample 6 Month SEO Plan That Anyone Can Follow' - https://medium.com/web-design-web-developer-magazine/sample-6-month-seo-plan-that-anyone-can-follow-61b8d7cd1139?sk=d2cc20af26309ca36ab5e45315a41035
• 'Thoughts on NFT Marketing and Why It's Easier for Generative and or Larger Sets Collections' - https://medium.com/web-design-web-developer-magazine/thoughts-on-nft-marketing-and-why-its-easier-for-generative-and-or-larger-sets-collections-73e4f80f903b?sk=237dee8cd3d17f5719adbe99fb12209b
• 'How NFT Teams Can Address the Problem of Timing and Momentum in Generative NFT Marketing' - https://medium.com/web-design-web-developer-magazine/how-nft-teams-can-address-the-problem-of-timing-and-momentum-in-generative-nft-marketing-6c3ee52c1c75?sk=e011327f3471b4097a5365c67e5deedb
• '11 Critical Youtube Video Site Best Practices for Optimal SEO' - https://medium.com/web-design-web-developer-magazine/11-critical-youtube-video-site-best-practices-for-optimal-seo-c6e1c438a90d?sk=644819d6852099d9fe569205c008cd87
• 'SEO Blogging 101 How to Set Up a Blog and What to Write' - https://medium.com/web-design-web-developer-magazine/seo-blogging-101-how-to-set-up-a-blog-and-what-to-write-ef430c48164d?sk=501b32ba0bca8d1f36eb298d8d47591d

____________________________________________________________________

EDUCATION

• B.A. English, Wilkes University

____________________________________________________________________

HOBBIES AND INTERESTS

• Stained glass work: https://jpd3.com/glass
• Music composition: https://jpd3.com/music
• Digital art: https://jpd3.com/art
• NFTs and web3: https://jpd3.com/nfts

____________________________________________________________________

SOCIAL MEDIA AND PORTFOLIO

• Amazon author page: https://amazon.com/author/jimdee
• Facebook author page: https://facebook.com/jpdbooks
• Facebook personal: https://facebook.com/rawfood
• Github: https://github.com/jpdnft
• Instagram: https://instagram.com/SwiggaJuice
• LinkedIn: https://linkedin.com/in/jimdee
• Medium: https://medium.com/@jimdee
• Twitter: https://twitter.com/SwiggaJuice
• Personal website: https://jpd3.com
• Books and writing:  https://jpd3.com/books

____________________________________________________________________

REFERENCES & RECOMMENDATIONS
• See https://www.linkedin.com/in/jimdee/


`;


const ResumeSocialMedia = () => {
  return (
    <div>
         
         <MainHeader />

         <style>{`
          @media print {
            div.mainpageHeader, p.breadcrumbs, h1, div.mainpageFooter, p.xprint, textarea { display: none; }
            div.mainpage { border:initial; }
          }
        `}</style>

        <div className="mainpage">
            
        <p className="breadcrumbs"><a href="/resume">back to /resume list</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="#additionalText">add additional info</a></p>

            <h1>SOCIAL MEDIA RESUME</h1>
            
            <pre className="preText" id="resumeText" style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>

            <textarea id="additionalText" placeholder="Paste additional information to append to this resume, then use copy link below."></textarea>

            <p class="xprint"><a href="###" onClick={copyToClipboard}>📋 Copy resume plus additional info.</a></p>

            <p class="xprint" className="breadcrumbs"><a href="/resume">back to /resume list</a></p>

        </div>

        <MainFooter />

    </div>
  );
}

export default ResumeSocialMedia;
