import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";


const copyToClipboard = () => {
  const resumeText = document.getElementById('resumeText').innerText;
  let totalText = '';
  const additionalText = document.getElementById('additionalText').value;
  if (additionalText) {
    let middleMessage = '\n____________________________________________________________________\nJIM DEE IS A PERFECT FIT FOR THE FOLLOWING ROLE: \n\n';
    totalText = resumeText + middleMessage + additionalText; 
  } else {
    totalText = resumeText + '\n' + additionalText; 
  }
  navigator.clipboard.writeText(totalText).then(() => {
    alert('Resume text copied to clipboard!');
  }).catch((err) => {
    console.error('Failed to copy text: ', err);
  });
}


const text = `
JIM DEE  
Jim@GenerativeNFTs.io   •   (503) 891-7153
jpd3.com   •   linkedin.com/in/jimdee   •   github.com/jpdnft

____________________________________________________________________

PROFESSIONAL SUMMARY

Seasoned professional with extensive experience in product management, web development, blockchain, and SEO. Proven track record of managing mission-critical projects and leading cross-functional teams in fast-paced environments. Adept at leveraging technical expertise, market insights, and data-driven decisions to drive growth, improve user satisfaction, and optimize performance. Experienced in the full product lifecycle and integrating customer feedback to refine product offerings. Well-accustomed to remote work / consultative scenarios.

____________________________________________________________________

PROFESSIONAL EXPERIENCE

★ Co-Founder / Chief Generative NFT Coder / Project Lead ⟶ GenerativeNFTs.io

• Managed full project lifecycle for large-scale blockchain applications, overseeing everything from scoping to execution, ensuring timely delivery and adherence to budgets.

• Analyzed key performance metrics, including user engagement and sales data, to optimize project outcomes, contributing to over $40 million USD in total sales.

• Implemented iterative UX changes based on client and user feedback, enhancing product quality and achieving measurable improvements in engagement and satisfaction.

• Led complex, large-scale web3 project management, including overseeing art pipeline, coding, and team strategy for international NFT drops.


★ Principal Web Strategist ⟶ Array Web Development

• Led design, development, and execution of websites and digital marketing campaigns.

• Led performance analysis using SEO metrics and user feedback to increase web traffic and reduce costs.

• Managed product lifecycle from initial concept to post-launch refinements, aligning project outcomes with client goals and user needs.

• Collaborated closely with clients to implement product improvements based on customer feedback, enhancing overall user satisfaction and improving ROI.

____________________________________________________________________

GENERAL BUSINESS SKILLS

project management / product management, business development and partnerships, digital marketing & SEO, web development & design, content creation & strategy -- ( see my blog at https://medium.com/@jimdee ), technical writing & documentation, public relations & media relations, team leadership & supervision, strategic planning, problem solving & critical thinking

____________________________________________________________________

TECHNICAL SKILLS

programming & development (Solidity, React, JSX, JavaScript, PHP, MySQL, HTML, CSS, Hardhat, Node.js, Firebase, GitHub), blockchain & web3: (smart contracts, ERC721/721A, Ethereum, Arweave, IPFS, Theta, OpenSea, MetaMask, etc.), SEO & digital marketing (Search Engine Optimization, content strategy, social media marketing, Google Analytics, Facebook ads), project management (Agile methodologies, team leadership, strategic planning, art pipeline management, international team coordination)

____________________________________________________________________

EDUCATION

• B.A. English, Wilkes University

____________________________________________________________________

SOCIAL MEDIA AND PORTFOLIO

• Facebook: https://facebook.com/rawfood
• Github: https://github.com/jpdnft
• Instagram: https://instagram.com/SwiggaJuice
• LinkedIn: https://linkedin.com/in/jimdee
• Medium: https://medium.com/@jimdee
• Twitter: https://twitter.com/SwiggaJuice
• Personal website / portfolio: https://jpd3.com


`;


const ResumeProductManager = () => {
  return (
    <div>
         
         <MainHeader />

         <style>{`
          @media print {
            div.mainpageHeader, p.breadcrumbs, h1, div.mainpageFooter, p.xprint, textarea { display: none; }
            div.mainpage { border:initial; }
          }
        `}</style>

        <div className="mainpage">
            
        <p className="breadcrumbs"><a href="/resume">back to /resume list</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a href="#additionalText">add additional info</a></p>

            <h1>PRODUCT MANAGER RESUME</h1>
            
            <pre className="preText" id="resumeText" style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>

            <textarea id="additionalText" placeholder="Paste additional information to append to this resume, then use copy link below."></textarea>

            <p class="xprint"><a href="###" onClick={copyToClipboard}>📋 Copy resume plus additional info.</a></p>

            <p class="xprint" className="breadcrumbs"><a href="/resume">back to /resume list</a></p>

        </div>

        <MainFooter />

    </div>
  );
}

export default ResumeProductManager;
