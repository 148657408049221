import React from 'react';
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

const Carpentry = () => {
  return (
    <div>
         
         <MainHeader />

        <div className="mainpage">
            
            <h1>🛠️ CARPENTRY / BOOK CASES / GLASS WORK / PAINTING</h1>

            <p>If you're reading this, chances are good that I sent you here regarding my construction and design services. Specifically, my specialties are as follows:</p>

            <ul>
              <li>Designing and building <b>custom book cases and libraries</b>;</li>
              <li><b>Stained glass work</b> (including incorporating custom glass work into furniture and book cases);</li>
              <li><b>Interior painting</b> and design;</li>
              <li>Possibly other design / build projects as well.</li>
            </ul>

            <p>➡️➡️➡️ <b>Ready to get going, and/or want a quote?</b> Email me anytime! <a href="/contact">Here's my contact page</a>.</p>

            <h3 style={{ marginTop: '40px' }}>🔧 Background & Qualifications</h3>
            <p>Over the years, I've rehabbed three homes top-to-bottom and have become quite good at most of the building trades &mdash; demo, framing, drywall, electrical, plumbing, painting, flooring, cabinetry, general carpentry, decks and outdoor projects, and total remodels. I've owned three homes in my life, all of them built in the early 1900s. So, chances are good that I've tackled almost every typical type of build or remodel work &mdash; from rebuilding kitchens and bathrooms from the studs out to even a full seismic retrofit. Regrettably, I've never kept a great photo record of these projects.</p>

            <h3 style={{ marginTop: '40px' }}>🪚 Book Cases & Libraries</h3>
            <p>Amidst my broad expertise, there's one passion that sets me apart &mdash; the art of crafting bespoke, built-in bookcases. What began as a love for literature (I was an English major in a former life!) has blossomed into a specialty, where meticulous attention to detail and a keen eye for design converge to create stunning, custom-built bookcases and libraries. I'll try to add some more (or better) pics, as I can find them, but here are a few shots of some of my bookcases:</p>

            <div className="allCards glassPage">

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/bookcases.jpg" />
                    <Card.Body>
                      <Card.Title>Built-in Book Cases</Card.Title>
                      <Card.Text>
                      Library-style book cases. Sorry, I don't have any better pics!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/bookcases1.jpg" />
                    <Card.Body>
                      <Card.Title>Built-in Book Cases</Card.Title>
                      <Card.Text>
                      New window and built-in book case with light.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/bookcase-ashram.jpg" />
                    <Card.Body>
                      <Card.Title>Built-in Book Cases</Card.Title>
                      <Card.Text>
                      Built these at an institution in PA.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/bookcase2.jpg" />
                    <Card.Body>
                      <Card.Title>Cubbies & Armoire</Card.Title>
                      <Card.Text>
                      Cubbies for an entryway with built-in book cases and built-in armoire/coat closet.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/bookcase3.jpg" />
                    <Card.Body>
                      <Card.Title>Library-Type Cases</Card.Title>
                      <Card.Text>
                      One of my earlier efforts, transforming a bedroom into a library.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/aibookcase.jpg" />
                    <Card.Body>
                      <Card.Title>You Dream It ...</Card.Title>
                      <Card.Text>
                      ...and I'll build it. (This one is actually an AI-created pic! Seriously, though, I can design your bookcase and/or we can ask AI for inspiration!)
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

            </div>

            <h3>🖌️ Interior Painting:</h3>

            <p>Honestly, painting is kind of a soft skill in my book, really. Let's be honest &mdash; you just need to be detail oriented, fairly patient, know the basics, and a steady hand doesn't hurt. But, more often than not, there's also a little more to it, as you usually have to do some minor (sometimes major) repairs as well before rolling it on. That said, I've done a ton of it over the past few decades, so I'm confident in my ability to do it for others. Most of these pics are from 2024. Notice the lack of gray, which was the "in" color scheme in the 20-teens. I should mention, I suppose, that what I like to do design-wise (when appropriate) is to bring photos of the to-be-painted areas into Photoshop and play around with color ideas. You'd be surprised at how useful tech is when applied to traditional building techniques sometimes.</p>

            <div className="allCards glassPage">

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/24045280_4_6.jpg" />
                    <Card.Body>
                      <Card.Title>Foyer</Card.Title>
                      <Card.Text>
                      I love working with metallic paints!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/24045280_7_6.jpg" />
                    <Card.Body>
                      <Card.Title>Living / Dining Room</Card.Title>
                      <Card.Text>
                      Gone with grays - welcome more modern colors!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/24045280_29_3.jpg" />
                    <Card.Body>
                      <Card.Title>Bedrooms</Card.Title>
                      <Card.Text>
                      Nice metallic blue!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/24045280_31_5.jpg" />
                    <Card.Body>
                      <Card.Title>Sunrom & Studio</Card.Title>
                      <Card.Text>
                      Another upgrade from the early 2000s gray!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/24045280_37_6.jpg" />
                    <Card.Body>
                      <Card.Title>Bedroom</Card.Title>
                      <Card.Text>
                      A deep metallic green!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/24045280_41_6.jpg" />
                    <Card.Body>
                      <Card.Title>Basements?!</Card.Title>
                      <Card.Text>
                      OMG, you should have seen it originally!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/20170814_134211.jpg" />
                    <Card.Body>
                      <Card.Title>Living Room</Card.Title>
                      <Card.Text>
                      Built those cubbies / bookcases on the left, too!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

            </div>

            <h3>🧰 Other General Construction Projects:</h3>

            <p>I really need to gather some better photos, but here's a brief record of a handful of projects. Most of them have interesting stories as well, such as that "Shed/Studio" below for which I came into a huge load of super rare wood on CraigsList one day. That kind of thing happens to me all the time, and I often utilize these types of materials into projects. One guy sold me about 80 inch-thick tabletops for about $300 and, man, did I ever put those things to all kinds of imaginative uses! Anyway, for now, some pics I have handy...</p>

            <div className="allCards glassPage">

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/10-10-lower-bath.jpg" />
                    <Card.Body>
                      <Card.Title>Bathroom</Card.Title>
                      <Card.Text>
                      My first bathroom remodel. Included a sink built into an antique vanity.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/10-10-sauna.jpg" />
                    <Card.Body>
                      <Card.Title>Sauna</Card.Title>
                      <Card.Text>
                      Part of that same bathroom -- included a giant sauna built into an old coal storage room.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/outdoorkitchen.webp" />
                    <Card.Body>
                      <Card.Title>Outdoor Kitchen</Card.Title>
                      <Card.Text>
                      Outdoor kitchen at our Portland home.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/kitchen1.jpg" />
                    <Card.Body>
                      <Card.Title>Kitchen Remodel</Card.Title>
                      <Card.Text>
                      Full custom build -- all cabinets from scratch, island, granite tile work, flooring, etc.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/kitchen2.jpg" />
                    <Card.Body>
                      <Card.Title>Kitchen Remodel</Card.Title>
                      <Card.Text>
                      Another shot of the same kitchen.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/shed1.jpg" />
                    <Card.Body>
                      <Card.Title>Shed / Studio</Card.Title>
                      <Card.Text>
                      Post-and-beam construction of a small 160 SF studio. (Later added decking.)
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/attic1.jpg" />
                    <Card.Body>
                      <Card.Title>Attic Remodel</Card.Title>
                      <Card.Text>
                      Complete remodel of an attic into a bedroom.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="pics/attic2.jpg" />
                    <Card.Body>
                      <Card.Title>Attic Remodel</Card.Title>
                      <Card.Text>
                      Second sleeping area.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>


                <div className="singleCard">
                  <Card>
                    <Card.Img variant="top" src="glass02.jpg" />
                    <Card.Body>
                      <Card.Title>Incorporating Custom Glass</Card.Title>
                      <Card.Text>
                      Sample piece where I did something really wild, implementing custom stained glass windows into a wild redesign.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>

            </div>

            <p>➡️➡️➡️ <b>Ready to get going, and/or want a quote?</b> Email me anytime! <a href="/contact">Here's my contact page</a>.</p>

        </div>

        <MainFooter />

    </div>
  );
}

export default Carpentry;
